import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  setShowAlert,
  setCopied,
  setCopiedTxt,
  setNoteTxt,
  setTableData,
  setBotTitle,
  setBotId,
  setBotPdf,
} from ".";

export const useSetShowAlert = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setShowAlert(val)), [dispatch]);
};

export const useSetCopied = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setCopied(val)), [dispatch]);
};

export const useSetCopiedTxt = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setCopiedTxt(val)), [dispatch]);
};

export const useSetNoteTxt = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setNoteTxt(val)), [dispatch]);
};

export const useSetTableData = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setTableData(val)), [dispatch]);
};
export const useSetBotTitle = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setBotTitle(val)), [dispatch]);
};

export const useSetBotId = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setBotId(val)), [dispatch]);
};

export const useSetBotPdf = () => {
  const dispatch = useDispatch();
  return useCallback((val) => dispatch(setBotPdf(val)), [dispatch]);
};