import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useSetShowAlert, useSetCopied } from "../state/app/hooks";

export default function AlertDialog({setPage, setMessageDialogOpen}) {
  const setShowAlert = useSetShowAlert();
  const setCopied = useSetCopied();
  const [isCopied, setIsCopied] = React.useState(false);

  const isShowAlert = useSelector((state) => state.app.isShowAlert);
  const curCopiedTxt = useSelector((state) => state.app.curCopiedTxt);

  const handleConfirm = () => {

    setCopied(true);
    setIsCopied(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
    // setPage(3);
    // setMessageDialogOpen(false);
  };

  const handleClose = () => {
    setIsCopied(false);
    setShowAlert(false);
  };

  useEffect(() => {
    isShowAlert && setIsCopied(false);
  }, [isShowAlert]);

  return (
    <React.Fragment>
      <Dialog
        open={isShowAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"是否加入個人筆記?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <MarkdownPreview source={curCopiedTxt} />
            {isCopied ? <><br/><p className="text-gray-500">已複製</p></> : <p></p>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleConfirm} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
