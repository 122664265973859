import React from "react";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSetCopied, useSetNoteTxt } from "../state/app/hooks";

const ClientSideEditor = () => {
  const setCopied = useSetCopied();
  const setNoteTxt = useSetNoteTxt();

  const noteText = useSelector((state) => state.app.noteText);

  const handleChange = (event) => {
    const updateContent = event.target.value;
    setNoteTxt(updateContent);
  };

  const handleClear = () => {
    setNoteTxt("");
    setCopied("");
  };

  const handleCopy = () => {
    if (noteText) {
      navigator.clipboard.writeText(noteText);
    }
  };

  return (
    <div className="max-w-screen mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-4">客戶端內容編輯器</h2>

      <textarea
        className="w-full h-[70vh] m-2 p-2 border rounded"
        value={noteText}
        onChange={handleChange}
        placeholder="在這裡輸入您的內容..."
      />
      <IconButton
        edge="start"
        onClick={handleClear}
        aria-label="claer"
        className="ml-2 bg-[#FFF]"
      >
        <DeleteForeverIcon />
      </IconButton>
      <IconButton
        edge="start"
        onClick={handleCopy}
        aria-label="claer"
        className="ml-2 bg-[#FFF]"
      >
        <ContentCopyIcon />
      </IconButton>
    </div>
  );
};

export default ClientSideEditor;
