const apiUrl = "https://xpert-api.azurefd.net/ai-course";

export const getSysMessageList = (params) => {
  return fetch(`${apiUrl}/api/v2/get-message-list-by-system`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then((data) => data);
};

export const getSysChapter = (params) => {
  return fetch(`${apiUrl}/api/v2/chapter/get`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    .then((data) => data);
};


export const newSysChapter = (params) => {
  return fetch(`${apiUrl}/api/v2/chapter/new`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
    //.then((data) => data);
};

export const requestSysMultipleChapter = (params) => {
  return fetch(`${apiUrl}/api/v2/chapter/new/multiple-request`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((response) => response.json())
};

export const sendSysMsg = (params) => {
    return fetch(
        `${apiUrl}/api/v2/send-question-by-system`,{
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      }
    )
    .then((response) => response.body)
    .then((body) => body);
  };


  export const getPdfPage = (params) => {
    return fetch(`https://xpert-api.azurefd.net/py/search_text`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => data);
  };