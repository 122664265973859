import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
} from "@mui/material";
import useHotkey from "./lib/hooks/use-hotkey"
import { KeywordContainer } from "./Component/EditKeyword";
import { ChatListContainer} from './ChatListComponent'
import { ExploreListContainer } from "./Component/ExploreList";
import ClientSideEditor from "./Component/ClientEditor";
import TableEditor from "./Component/TableEditor";
import { ChatPdf } from "./Component/ChatPdf";
import {
  CreateContainer,
  AvatarContainer,
  UploadContainer,
} from "./BotComponent";
// import { ProfileContainer} from './ProfileComponent'
import { MessageContainer } from "./MessageComponent";
import AlertDialog from "./Component/AlertDialog";
import { Message } from "./Component/Message";

var height = document.documentElement.clientHeight;
// var width  = window.screen.width

const BottomNavigationActionButton = styled(BottomNavigationAction)({
  color: "#757575",
  "&.Mui-selected": {
    color: "#FFB41D",
  },
});

function App() {
  useHotkey("/", () => setPage(2));
  const [page, setPage] = useState(2);

  const [walletAddress, setWalletAddress] = React.useState("doc-numiner-addr");
  //const hashValue = window.location.hash;


  const [bot, setBot] = useState(null);

  const [chatDialogOpen, setChatDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  // const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [searchKeywordDialogOpen, setSearchKeywordDialogOpen] = useState(false);

  const dialogStyles = {
    "& .MuiPaper-root": {
      background: "linear-gradient(#000000,#052F41)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  };

  // useEffect(() => {
  //   if (hashValue) {
  //     setPage(2);
  //   }
  // }, [hashValue]);

  return (
    <React.Fragment>
      <Box
        style={{
          position: "fixed",
          width: "100%",
          height: height - 42,
          background: "linear-gradient(#000000,#052F41)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          overflow: "auto",
          visibility: page == 2 ? "visible" : "hidden",
        }}
      >
        {page == 2 ? (
          <ChatListContainer
            {...{
              walletAddress: walletAddress,
              chatRoomCallback: (bot) => {
                if (bot) {
                  setBot(bot);
                  setMessageDialogOpen(true);
                }
              },
            }}
          />
        ) : (
          <Box />
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: { height },
          backgroundColor: "#ffffff",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Box
          style={{
            visibility: page == 1 ? "visible" : "hidden",
          }}
          className="fixed w-full h-[calc(100%-44px)] bg-gradient-to-b from-[#000] to-[#052F41] bg-no-repeat bg-100% overflow-auto"
        >
          {page == 1 ? (
            <ChatPdf
              {...{
                walletAddress,
                chatRoomCallback: (bot) => {
                  if (bot) {
                    setBot(bot);
                    setChatDialogOpen(true);
                  }
                },
              }}
            />
          ) : (
            <Box />
          )}
        </Box>

        <Box
          style={{
            visibility: page == 3 ? "visible" : "hidden",
          }}
          className="fixed w-full h-[calc(100%-44px)] bg-gradient-to-b from-[#000] to-[#052F41] bg-no-repeat bg-100% overflow-auto"
        >
          {page == 3 ? (
            <ClientSideEditor/>
          ) : (
            <Box />
          )}
        </Box>

        <Box
          style={{
            visibility: page == 4 ? "visible" : "hidden",
          }}
          className="fixed w-full h-[calc(100%-44px)] bg-gradient-to-b from-[#000] to-[#052F41] bg-no-repeat bg-100% overflow-auto"
        >
          {page == 4 ? (
            <TableEditor {...{setPage, walletAddress}}/>
          ) : (
            <Box />
          )}
        </Box>

        {/* <Box
          style={{
            position: "fixed",
            width: "100%",
            height: height - 42,
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            overflow: "auto",
            visibility: page == 3 ? "visible" : "hidden",
          }}
        >
          {page == 3 ? (
            <ExploreListContainer
              {...{
                chatRoomCallback: (bot) => {
                  if (bot) {
                    setBot(bot);
                    setMessageDialogOpen(true);
                  }
                },
              }}
            />
          ) : (
            <Box />
          )}
        </Box> */}

        <Box
          style={{
            position: "fixed",
            width: "100%",
            top: "auto",
            bottom: 0,
            backgroundColor: "#ffffff",
          }}
        >
          <BottomNavigation
            value={page}
            onChange={(event, newValue) => {

              if (newValue == 0) {
                setCreateDialogOpen(true);
              } else {
                setPage(newValue);
              }
              // else if(newValue==4){
              //   setProfileDialogOpen(true)
              // }
            }}
            showLabels
            className="bg-[#000000F0] h-11"
          >
            <BottomNavigationActionButton label="檔案上傳" showLabel={true} />
            <BottomNavigationActionButton label="文檔搜尋" showLabel={true} />
            <BottomNavigationActionButton label="檔案列表" showLabel={true} />
            <BottomNavigationActionButton label="個人筆記" showLabel={true} />
            {/* <BottomNavigationActionButton label="檔案列表" showLabel={true} /> */}
          </BottomNavigation>
        </Box>
      </Box>

      <Dialog
        fullScreen
        open={searchKeywordDialogOpen}
        onClose={() => {
          setSearchKeywordDialogOpen(false);
        }}
        sx={dialogStyles}
      >
        <KeywordContainer
          {...{ setSearchKeywordDialogOpen, setChatDialogOpen }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={chatDialogOpen}
        onClose={() => {
          setChatDialogOpen(false);
        }}
        sx={dialogStyles}
      >
        <Message
          {...{
            walletAddress: walletAddress,
            bot: bot,
            setChatDialogOpen,
            setPage,
            uploadCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setUploadDialogOpen(true);
              }
            },
            avatarCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setAvatarDialogOpen(true);
              }
            },
            keyCallback: (bot) => {
              setSearchKeywordDialogOpen(true);
            },
          }}
        />
      </Dialog>
   
      <Dialog
        fullScreen
        open={messageDialogOpen}
        onClose={() => {
          setMessageDialogOpen(false);
        }}
        sx={dialogStyles}
      >
        <MessageContainer
          {...{
            setPage,
            walletAddress: walletAddress,
            bot: bot,
            setMessageDialogOpen,
            uploadCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setUploadDialogOpen(true);
              }
            },
            avatarCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setAvatarDialogOpen(true);
              }
            },
          }}
        />
      </Dialog>

      <AlertDialog {...{setPage, setMessageDialogOpen}}/>

      <Dialog
        fullScreen
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <CreateContainer
          {...{
            walletAddress,
            setCreateDialogOpen,
            createCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setCreateDialogOpen(false);
                setUploadDialogOpen(true);
              }
            },
          }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={uploadDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
        }}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <UploadContainer
          {...{
            walletAddress,
            bot: bot,
            setUploadDialogOpen,
            chatRoomCallback: (bot) => {
              if (bot) {
                setUploadDialogOpen(false);
                setBot(bot);
                setMessageDialogOpen(true);
              }
            },
          }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={avatarDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
        }}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <AvatarContainer
          {...{
            walletAddress,
            bot: bot,
            setAvatarDialogOpen,
            avatarCallback: (bot) => {
              if (bot) {
                setAvatarDialogOpen(false);
                setBot(bot);
                setMessageDialogOpen(true);
              }
            },
            deleteBotCallback: () => {
              setAvatarDialogOpen(false);
              setPage(2);
              setMessageDialogOpen(false);
            },
          }}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default App;
