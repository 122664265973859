import React, { useEffect, useState, useMemo } from "react";

import Box from "@mui/material/Box";

import "react-chat-elements/dist/main.css";
import { ChatList } from "react-chat-elements";

import { getChatList, getMyChatList } from "./api/getMainnetInfuraApi";

export const ChatListContainer = ({ walletAddress, chatRoomCallback }) => {
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [bot, setBot] = useState(null);

  const searchParams = new URLSearchParams(window.location.search);
  const chatId = searchParams.get("id") || "";

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    getMyChatList({
      walletAddress: walletAddress,
    }).then((result) => {
      if (result.code == 0) {
        setChatList(result.data);
      }
    });
  }, [walletAddress]);

  useEffect(() => {
    if (chatId && chatList) {
      const chatChannelId =
        chatList.findIndex((item) => item.botId === chatId) === -1
          ? chatId
          : chatList.findIndex((item) => item.botId === chatId);
      chatRoomCallback(chatList[chatChannelId]);
    }
  }, [chatId, chatList]);

  return (
    <Box style={{}}>
      <ChatList
        className="chat-list"
        dataSource={chatList}
        onClick={(bot) => {
          console.log("bot " + JSON.stringify(bot));
          chatRoomCallback(bot);
        }}
      />
    </Box>
  );
};
