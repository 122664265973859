import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useSetTableData } from "../state/app/hooks";
import { IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { getSysChapter, requestSysMultipleChapter } from "../api/systemApi";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

import { getPdfPage } from "../api/systemApi";

const EditableCell = ({ value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleSave = () => {
    setIsEditing(false);
    onSave(inputValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <td className="px-4 py-2 border" onClick={() => setIsEditing(true)}>
      {isEditing ? (
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleSave}
          onKeyDown={handleKeyDown}
          autoFocus
          className="w-full px-2 py-1 border rounded"
        />
      ) : (
        value
      )}
    </td>
  );
};

const ScoreTable = ({ isoData, sheet, botPdf }) => {
  const tableData = useSelector((state) => state.app.tableData);
  const [isHovered, setIsHovered] = useState(null);
  const [isHoveredScore, setIsHoveredScore] = useState(null);
  const [data, setData] = useState([]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const openPdf = (index, source) => {

    if (!botPdf.url) {
      console.log("no pdf url");
    }

    if (botPdf.name && source.length > 10) {
      let searchText = source.substring(0, 50).replace(/\+/g, '').trim();
      const nameWithoutExtension = botPdf.name.split(".pdf")[0];
      getPdfPage({
        file_path: nameWithoutExtension,
        search_text: searchText,
      }).then((result) => {
        window.open(`${botPdf.url}#page=${result.page}`);
      }).catch((error) => {
        const randomNumber = Math.floor(Math.random() * 11) + 10;
        window.open(`${botPdf.url}#page=${randomNumber}`);
      });
    }

    console.log(botPdf);
  };

  useEffect(() => {
    let mergedData = isoData.map((item, index) => ({
      ...item,
      content: sheet[item.id]?.explain || item.explain,
      score: sheet[item.id]?.score || item.score,
      source: sheet[item.id]?.sourceText || "",
      standard: sheet[item.id]?.standard || "",
    }));

    setData(mergedData);
  }, [tableData, sheet]);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 mt-2">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="border border-gray-300">盤查重點說明</th>
            <th className="border border-gray-300">修改建議與評分說明</th>
            <th className="border border-gray-300">分數</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              if (item.title) {
                return (
                  <tr>
                    <th
                      className="bg-[#7293a1] border-r text-[#FFF]"
                      colSpan="3"
                    >
                      {item.title}
                    </th>
                  </tr>
                );
              }

              return (
                <tr
                  key={index}
                  className={`hover:bg-gray-100 ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="border">{item.desp}</td>
                  <td className="px-4 py-2 border">
                    {item.content}
                    <div className="flex space-x-2">
                      <div
                        className="cursor-help"
                        onMouseEnter={() => setIsHovered(index)}
                        onMouseLeave={() => setIsHovered(null)}
                      >
                        {item.source && <InfoOutlinedIcon />}

                        {isHovered === index && (
                          <div className="absolute z-50 w-[800px] p-2 text-sm bg-[#FFF] border rounded-lg shadow-lg">
                            <div className="relative">
                              <MarkdownPreview
                                className="text-[#374151]"
                                source={truncateText(item.source, 240)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="cursor-help"
                        onClick={() => openPdf(index, item.source)}
                        onMouseLeave={() => setIsHovered(null)}
                      >
                        {item.source && <ContentPasteSearchIcon />}
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 border">
                    {item.score}
                    <div
                      className="cursor-help"
                      onMouseEnter={() => setIsHoveredScore(index)}
                      onMouseLeave={() => setIsHoveredScore(null)}
                    >
                      {item.standard && <InfoOutlinedIcon />}

                      {isHoveredScore === index && (
                        <div className="absolute z-50 w-[800px] p-2 text-sm bg-[#FFF] border rounded-lg shadow-lg right-0 ">
                          <div className="relative">
                            <p className="text-[#374151]">{item.standard}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const CarbonEmissionTable = () => {
  const botTitle = useSelector((state) => state.app.botTitle);
  const tableData = useSelector((state) => state.app.tableData);
  const setTableData = useSetTableData();
  const [data, setData] = useState([
    {
      category: "類別一",
      amount: "",
      standard: "12000",
      average: "10802",
      rate: "",
    },
    {
      category: "類別二",
      amount: "",
      standard: "2500",
      average: "2200",
      rate: "",
    },
    {
      category: "類別三",
      amount: "",
      standard: "4000",
      average: "4500",
      rate: "",
    },
    {
      category: "類別四",
      amount: "",
      standard: "250000",
      average: "200000",
      rate: "",
    },
    { category: "類別五", amount: "", standard: "", average: "", rate: "" },
    { category: "類別六", amount: "", standard: "", average: "", rate: "" },
    { category: "", amount: "", total: "" },
  ]);

  const handleSave = (index, newValue, field) => {
    const newData = [...data];
    const formattedValue = parseFloat(
      newValue.replace(/,/g, ""),
      10
    ).toLocaleString();
    newData[index] = { ...newData[index], [field]: formattedValue };
    setData(newData);
  };

  useEffect(() => {
    const calculateItemRate = (amount, standard) => {
      if (!amount) return "";
      if (!standard) return "";

      let intAmount = parseFloat(amount?.replace(/,/g, ""), 10);
      let intStandard = parseFloat(standard?.replace(/,/g, ""), 10);

      if (intAmount > intStandard) return 0;

      let rate = (intAmount / intStandard).toFixed(2);

      let newValue = 5;

      if (rate > 0.9) {
        newValue = 1;
        return newValue;
      }
      if (rate > 0.8) {
        newValue = 2;
        return newValue;
      }
      if (rate > 0.7) {
        newValue = 3;
        return newValue;
      }
      if (rate > 0.6) {
        newValue = 4;
        return newValue;
      }
      return newValue;
    };

    const mergedData = data.map((item, index) => ({
      ...item,
      amount: tableData[index]?.amount || item.amount,
      rate: item?.standard && calculateItemRate(item?.amount, item?.standard),
    }));
    setData(mergedData);

    console.log("tableData:", tableData);
  }, [tableData]);

  const classes = {
    0: "（直接溫室氣體排放和移除）",
    1: "（輸入能源的間接溫室氣體排放）",
    2: "（運輸中的間接溫室氣體排放）",
    3: "（使用產品的間接溫室氣體排放)",
    4: "（與使用產品有關的間接溫室氣體排放）",
    5: " (其他來源的間接溫室氣體排放)",
  };

  const calculateAverageRate = () => {
    const validRates = data.filter(
      (item) => item.rate !== "" && item.rate !== undefined
    );
    // 計算總和
    const totalRate = validRates.reduce(
      (sum, item) => sum + parseFloat(item.rate),
      0
    );
    // 計算平均值
    const averageRate =
      validRates.length > 0 ? totalRate / validRates.length : 0;

    return averageRate;
  };

  const averageRate = calculateAverageRate();

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 mt-2">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="bg-[#7293a1] border-r text-[#FFF]" colSpan="1">
              {botTitle}
            </th>
            <th className="bg-[#7293a1] border-l text-[#FFF]" colSpan="5">
              ISO 14064-1:2018 溫室氣體盤查
            </th>
          </tr>
          <tr>
            <th className="border border-gray-300">類別</th>
            <th className="border border-gray-300">排放量（t-CO₂e）</th>
            <th className="border border-gray-300">合規排放量</th>
            <th className="border border-gray-300">同業平均標準</th>
            <th className="border border-gray-300">是否通過</th>
            <th className="border border-gray-300">評分</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              if (index === data.length - 1) {
                return (
                  <>
                    <td className="text-center "></td>
                    <td className="text-center "></td>
                    <td className="text-center "></td>
                    <td className="text-center "></td>
                    <td className="text-center "></td>
                    <td className="text-center border">{averageRate}</td>
                  </>
                );
              }
              return (
                <tr
                  key={index}
                  className={`hover:bg-gray-100 ${
                    index % 2 === 0 ? "bg-gray-50" : "bg-white"
                  }`}
                >
                  <td className="text-center border">
                    {item.category}
                    <br />
                    {classes[index]}
                  </td>
                  <td className="px-4 py-2 border">{item.amount}</td>
                  <EditableCell
                    value={item?.standard}
                    onSave={(newValue) =>
                      handleSave(index, newValue, "standard")
                    }
                  />
                  <EditableCell
                    value={item?.average}
                    onSave={(newValue) =>
                      handleSave(index, newValue, "average")
                    }
                  />
                  <td className="px-4 py-2 border text-center">
                    {parseFloat(item.amount.replace(/,/g, ""), 10) <
                    parseFloat(item?.standard?.replace(/,/g, ""), 10)
                      ? "O"
                      : "X"}
                  </td>
                  <EditableCell
                    value={item?.rate}
                    onSave={(newValue) => handleSave(index, newValue, "rate")}
                  />
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export const mydata = [
  {
    title: "設定組織邊界",
  },
  {
    id: "1a",
    desp: "組織邊界設定方式",
    content: "修改建議",
  },
  {
    id: "1b",
    desp: "一致性的組織邊界設定原則，並避免嚴重的遺漏與重覆計算",
    content: "修改建議",
  },
  {
    id: "1c",
    desp: "排放量盤查需依組織邊界改變而調整",
    content: "修改建議",
  },
  {
    title: "設定報告邊界",
  },
  {
    id: "2a",
    desp: "直接/間接排放源鑑別的完整性",
    content: "修改建議",
  },
  {
    id: "2b",
    desp: "報告邊界與事業流程/活動的一致性",
    content: "修改建議",
  },
  {
    id: "2c",
    desp: "報告邊界採取一致性的設定方法",
    content: "修改建議",
  },
  {
    id: "2d",
    desp: "排放量盤查需依報告邊界改變而調整",
    content: "修改建議",
  },
  {
    title: "間接溫室氣體盤查",
  },
  {
    id: "3a",
    desp: "組織應透過顯著性準則揭露顯著之間接排放",
    content: "修改建議",
  },
  {
    id: "3b",
    desp: "盡可能與供應鏈配合共同執行",
    content: "修改建議",
  },
  {
    id: "3c",
    desp: "依照 5 大原則進行呈現",
    content: "修改建議",
  },
  {
    id: "3d",
    desp: "可列為未來間接溫室氣體減量之規劃",
    content: "修改建議",
  },
  {
    title: "設定基準年",
  },
  {
    id: "4a",
    desp: "可用單一年度或過去連續數年之平均值當作基準年",
    content: "修改建議",
  },
  {
    id: "4b",
    desp: "基準年調整/修改做法應明確適當且文件化資訊",
    content: "修改建議",
  },
  {
    id: "4c",
    desp: "基準年的實際調整狀況是否符合需求",
    content: "修改建議",
  },
  {
    title: "溫室氣體量化方法",
  },
  {
    id: "5a",
    desp: "活動數據與規範要求、排放源實際狀況的符合性",
    content: "修改建議",
  },
  {
    id: "5b",
    desp: "活動數據來源與量化方式或排放係數計算條件的符合性",
    content: "修改建議",
  },
  {
    id: "5c",
    desp: "活動數據資料是否有能夠充足的佐證來源",
    content: "修改建議",
  },
  // {
  //   id: "5d",
  //   desp: "排放源的活動數據與排放係數所採用之資料的適切性",
  //   content: "修改建議",
  // },
  // {
  //   id: "5e",
  //   desp: "依盤查目的或協議之要求採用排放係數及全球暖化潛勢值(GWP)",
  //   content: "修改建議",
  // },
  // {
  //   id: "5f",
  //   desp: "排放係數預設值與設備之操作狀況的符合性",
  //   content: "修改建議",
  // },
  // {
  //   id: "5g",
  //   desp: "自行發展排放係數取樣的代表性",
  //   content: "修改建議",
  // },
  // {
  //   id: "5h",
  //   desp: "計算與排放量相關的調整方法",
  //   content: "修改建議",
  // },
  // {
  //   id: "5i",
  //   desp: "製程中溫室氣體排放量的關鍵操作參數",
  //   content: "修改建議",
  // },
  // {
  //   id: "5j",
  //   desp: "量化方法的資料來源其完整性與一致性",
  //   content: "修改建議",
  // },
  // {
  //   id: "5k",
  //   desp: "盤查工具表單之排放量計算彙整的正確性",
  //   content: "修改建議",
  // },
];

//   "5d. 排放源的活動數據與排放係數所採用之資料的適切性。",
//   "5e. 依盤查目的或協議之要求採用排放係數及全球暖化潛勢值(GWP)。",
//   "5f. 排放係數預設值與設備之操作狀況的符合性。",
//   "5g. 自行發展排放係數取樣的代表性。",
//   "5h. 計算與排放量相關的調整方法。",
//   "5i. 製程中溫室氣體排放量的關鍵操作參數。",
//   "5j. 量化方法的資料來源其完整性與一致性。",
//   "5k. 盤查工具表單之排放量計算彙整的正確性。",

export const questions = [
  {
    id: "1a",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "1a. 組織邊界設定方式，如：營運控制權法、財務控制權法或股權比例法。" 
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "1a", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "1b",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "2a. 直接/間接排放源鑑別的完整性。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "1b", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "1c",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "1c. 排放量盤查需依組織邊界改變而調整。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "1c", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "2a",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "2a. 直接/間接排放源鑑別的完整性。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "2a", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "2b",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "2b. 報告邊界與事業流程/活動的一致性。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "2b", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "2c",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "2c. 報告邊界採取一致性的設定方法。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "2c", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "2d",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "2d. 排放量盤查需依報告邊界改變而調整。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "2d", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "3a",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "3a. 組織應透過顯著性準則揭露顯著之間接排放。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "3a", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "3b",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "3b. 盡可能與供應鏈配合共同執行。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "3b", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "3c",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "3c. 依照5大原則進行呈現。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "3c", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "3d",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "3d. 可列為未來間接溫室氣體減量之規劃。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "3d", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "4a",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "4a. 可用單一年度或過去連續數年之平均值當作基準年。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "4a", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "4b",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "4b. 基準年調整/修改做法應明確適當且文件化資訊。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "4b", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "4c",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "4c. 基準年的實際調整狀況是否符合需求。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "4c", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "5a",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "5a. 活動數據與規範要求、排放源實際狀況的符合性。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "5a", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "5b",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
    "5b. 活動數據來源與量化方式或排放係數計算條件的符合性。"
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "5b", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
  {
    id: "5c",
    question: `根據整本書的內容 根據以下問題為標準評分 1~100
     "5c. 活動數據資料是否有能夠充足的佐證來源。",
    回覆無需引言 說明原因請儘量詳細 並提供為何如此評分的標準 請回覆成物件的格式 { "id": "5c", "explain": "說明原因 內容不少於100字..", "standard": "評分標準","score": "評分" };`,
  },
];
export const ids = questions.map((question) => question.id);
const TableEditor = ({ setPage, walletAddress }) => {
  const botId = useSelector((state) => state.app.botId);
  const botTitle = useSelector((state) => state.app.botTitle);
  const botPdf = useSelector((state) => state.app.botPdf);
  const [loading, setLoading] = useState(false);
  const [sheet, setSheet] = useState("");

  const [isoData, setIsoData] = useState(mydata);

  const create = async () => {
    if (!walletAddress || !botId) {
      console.log("no walletAddress or botId");
      return;
    }

    const generateRequests = (questions, walletAddress, botId) => {
      return questions.map(({ id, question }) => ({
        tag: id,
        walletAddress,
        botId,
        question,
        threshold: 0.7,
        model: "gpt-4o",
      }));
    };

    try {
      const requests = generateRequests(questions, walletAddress, botId);
      const stream = await requestSysMultipleChapter(requests);

      console.log("generateRequests:", stream);
      if (stream) return "success";
    } catch (error) {
      console.error("sendSysMsg error:", error);
      return error;
    }
  };

  useEffect(() => {
    const processJsonString = (text) => {
      let jsonString = text.replace(/\s+/g, " "); // 將多餘的空白替換為單個空格

      jsonString = jsonString.replace(/.*{\s*/g, "{").replace(/\s*}.*/g, "}"); // 移除花括號周圍內容
      jsonString = jsonString
        .replace(/\s*\[\s*/g, "[ ")
        .replace(/\s*\]\s*/g, " ]"); // 移除方括號周圍的多餘空白
      return jsonString;
    };

    getSysChapter({
      walletAddress: walletAddress,
      botId: botId,
      tags: ids,
    }).then((result) => {
      if (result && result.length > 0) {
        const data = result.reduce((acc, item) => {
          if (!acc[item.tag] || acc[item.tag].index < item.index) {
            let jsonString = processJsonString(item.answer);
            acc[item.tag] = { ...item, ...JSON.parse(jsonString) };
          }
          return acc;
        }, {});
        setSheet(data);
      }
    });
  }, [botId]);

  return (
    <>
      <div className="flex justify-between">
        <IconButton
          className="text-[#FFF]"
          edge="start"
          color="inherit"
          onClick={() => {
            setPage(2);
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <span className="text-[#FFF] flex items-center justify-center">
          <a href={botPdf.url} target="_blank" rel="noopener noreferrer">
            {botTitle}
          </a>
        </span>
        <IconButton
          className="text-[#FFF]"
          edge="start"
          color="inherit"
          onClick={() => {
            setLoading(true);
            create()
              .then((response) => {
                console.log("create最終回應:", response);
              })
              .catch((error) => {
                console.error("create錯誤:", error);
              })
              .finally(() => {
                setLoading(false);
                // 重整頁面
                window.location.reload();
              });
          }}
          aria-label="close"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : <PostAddIcon />}
        </IconButton>
      </div>

      <div className="max-w-screen mx-auto mt-10 bg-[#FFF]">
        <ScoreTable {...{ isoData, sheet, botPdf }} />
        <CarbonEmissionTable />
      </div>
    </>
  );
};

export default TableEditor;
