import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowAlert: false,
  isCopied: false,
  curCopiedTxt:'',
  noteText: '',
  tableData: '',
  botTitle: '',
  botId: '',
  botPdf: {},
};

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setShowAlert: (state, action) => {
      state.isShowAlert = action.payload;
    },
    setCopied: (state, action) => {
      state.isCopied = action.payload;
    },
    setCopiedTxt: (state, action) => {
      state.curCopiedTxt = action.payload;
    },
    setNoteTxt: (state, action) => {
      state.noteText = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setBotTitle: (state, action) => {
      state.botTitle = action.payload;
    },
    setBotId: (state, action) => {
      state.botId = action.payload;
    },
    setBotPdf: (state, action) => {
      state.botPdf = action.payload;
    },
  },
});

// Actions
export const {
  setShowAlert,  
  setCopied,
  setCopiedTxt,
  setNoteTxt,
  setTableData,
  setBotTitle,
  setBotId,
  setBotPdf,
} = appSlice.actions;

export default appSlice.reducer;
