import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";

import {
  AppBar,
  Button,
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import "react-chat-elements/dist/main.css";

import * as pdfjsLib from "pdfjs-dist";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";

import {
  encode,
  decode,
  isWithinTokenLimit,
} from "gpt-tokenizer/model/text-embedding-ada-002";

import {
  postCreateBot,
  postUpdateKnowledgeBase,
  postCreateAvatar,
  postUploadAvatar,
  getKnowledgeBase,
  deleteKnowledgeBase,
  deleteBot,
  postUpdateBot,
  uploadSourceFile,
} from "./api/getMainnetInfuraApi";

const InputContainer = styled.div`
  height: 44;
  width: 282;
  background-color: #333333;
  border-radius: 18px;
`;
const styleButton = {
  height: 44,
  width: 120,
  background: "linear-gradient(#333333,#333333)",
  borderRadius: 4,
  fontSize: 16,
  color: "#ffffff",
};

export const CreateContainer = ({
  walletAddress,
  setCreateDialogOpen,
  createCallback,
}) => {
  const [botName, setBotName] = useState("");
  const [botSubtitle, setBotSubtitle] = useState("");
  const [botDescription, setBotDescription] = useState("");

  return (
    <Box
      style={{
        flexDirection: "column",
        display: "flex",
      }}
    >
      <AppBar
        sx={{
          position: "fixed",
          height: 40,
        }}
      >
        <Toolbar
          sx={{
            minHeight: "5px !important",
            display: "flex",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setCreateDialogOpen(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            檔案上傳
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 40,
        }}
      />
      <Box className="w-80 flex flex-col self-center">
        <Typography className="text-[#FFF] text-sm mt-4">新增名稱</Typography>

        <InputContainer>
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={"名稱"}
            onChange={(event) => {
              var text = event.target.value;
              setBotName(text);
            }}
          />
        </InputContainer>

        <Typography className="text-[#FFF] text-sm mt-4">新增標題</Typography>

        <InputContainer>
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={"標題"}
            onChange={(event) => {
              var text = event.target.value;
              setBotSubtitle(text);
            }}
          />
        </InputContainer>

        {/* <Typography className="text-[#FFF] text-sm mt-4">
          新增描述
        </Typography>

        <InputContainer>
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            multiline
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={"描述"}
            onChange={(event) => {
              var text = event.target.value;
              setBotDescription(text);
            }}
          />
        </InputContainer> */}

        <div style={{ height: 18 }} />

        <Box className="self-center">
          <Button
            sx={styleButton}
            onClick={() => {
              if (botName == "" || botSubtitle == "") {
                //  || botDescription == ""
                return;
              }
              postCreateBot({
                walletAddress: walletAddress,
                name: botName,
                subtitle: botSubtitle,
                description: botDescription,
              })
                .then((result) => {
                  if (result.code == 0) {
                    createCallback(result.data);
                  }
                })
                .catch((err) => {
                  console.log("err " + err);
                });
            }}
          >
            創建
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const AvatarContainer = ({
  walletAddress,
  bot,
  setAvatarDialogOpen,
  avatarCallback,
  deleteBotCallback,
}) => {
  const [botName, setBotName] = useState("");
  const [botSubtitle, setBotSubtitle] = useState("");
  const [botDescription, setBotDescription] = useState("");

  const [prompt, setPrompt] = useState("");
  const [images, setImages] = useState([]);

  return (
    <Box
      style={{
        flexDirection: "column",
        display: "flex",
      }}
    >
      <AppBar
        sx={{
          position: "fixed",
          height: 40,
        }}
      >
        <Toolbar
          sx={{
            minHeight: "5px !important",
            display: "flex",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setAvatarDialogOpen(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit {bot.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 40,
        }}
      />
      <Box
        style={{
          width: 320,
          flexDirection: "column",
          display: "flex",
          alignSelf: "center",
        }}
      >
        <div style={{ height: 18 }} />

        <Typography
          style={{
            color: "#ffffff",
            fontSize: 14,
          }}
        >
          修改名稱:
        </Typography>

        <InputContainer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={bot.title}
            onChange={(event) => {
              var text = event.target.value;
              setBotName(text);
            }}
          />
        </InputContainer>

        <div style={{ height: 18 }} />

        <Typography
          style={{
            color: "#ffffff",
            fontSize: 14,
          }}
        >
          修改標題:
        </Typography>

        <InputContainer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={bot.subtitle}
            onChange={(event) => {
              var text = event.target.value;
              setBotSubtitle(text);
            }}
          />
        </InputContainer>

        <div style={{ height: 18 }} />

        {/* <Typography
            style={{
              color: "#ffffff",
              fontSize: 14,
            }}
          >
            Edit Description
          </Typography> */}
        {/* 
        <InputContainer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            multiline
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={bot.description}
            onChange={(event) => {
              var text = event.target.value;
              setBotDescription(text);
            }}
          />
        </InputContainer> */}

        <div style={{ height: 18 }} />

        <Box
          style={{
            alignSelf: "center",
          }}
        >
          <Button
            component="label"
            sx={styleButton}
            onClick={() => {
              var params = {
                walletAddress: walletAddress,
                botId: bot.botId,
              };
              if (botName) {
                params.name = botName;
              }
              if (botSubtitle) {
                params.subtitle = botSubtitle;
              }
              if (botDescription) {
                params.description = botDescription;
              }

              postUpdateBot(params).then((result) => {
                if (result.code == 0) {
                  window.alert("success");
                  avatarCallback(bot);
                }
              });
            }}
          >
            上傳
          </Button>
        </Box>

        {/* <div style={{ height: 18 }} />

        <Typography
          style={{
            color: "#ffffff",
            fontSize: 14,
          }}
        >
          生成圖片：
        </Typography>

        <div style={{ height: 18 }} />

        <InputContainer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            variant="standard"
            margin="dense"
            fullWidth
            style={{
              marginLeft: 16,
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                color: "#ffffff",
              },
            }}
            placeholder={"Example:Cat"}
            onChange={(event) => {
              if (event.target.value) {
                setPrompt(event.target.value);
              }
            }}
          />
        </InputContainer>

        <div style={{ height: 18 }} />

        <Box
          style={{
            alignSelf: "center",
          }}
        >
          <Button
            component="label"
            sx={styleButton}
            onClick={() => {
              if (prompt) {
                postCreateAvatar({
                  prompt: prompt,
                }).then((result) => {
                  if (result) {
                    setImages(result.images);
                  }
                });
              }
            }}
          >
            生成
          </Button>
        </Box> */}

        <div style={{ height: 18 }} />

        {images.map((image) => {
          return (
            <Box
              style={{
                width: 320,
                flexDirection: "column",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <img src={"data:image/png;base64, " + image} />

              <div style={{ height: 12 }} />

              <Box
                style={{
                  alignSelf: "center",
                }}
              >
                <Button
                  component="label"
                  sx={styleButton}
                  onClick={() => {
                    if (image) {
                      // var imageUpload = new Image();
                      // imageUpload.src =
                      var mimeType = "data:image/png";
                      var fileName = new Date().getTime() + ".png";

                      fetch("data:image/png;base64, " + image)
                        .then((res) => res.arrayBuffer())
                        .then(
                          (buf) => new File([buf], fileName, { type: mimeType })
                        )
                        .then((file) => {
                          const formData = new FormData();

                          formData.append("file", file);
                          formData.append("walletAddress", walletAddress);
                          formData.append("botId", bot.botId);

                          return postUploadAvatar(formData);
                        })
                        .then((result) => {
                          if (result.code == 0) {
                            window.alert("success");
                            avatarCallback(bot);
                          }
                        });
                    }
                  }}
                >
                  上傳圖片
                </Button>
              </Box>

              <div style={{ height: 18 }} />
            </Box>
          );
        })}

        <div style={{ height: 18 }} />

        <Box
          style={{
            alignSelf: "center",
          }}
        >
          <Button
            component="label"
            sx={styleButton}
            onClick={() => {
              deleteBot({
                walletAddress: walletAddress,
                botId: bot.botId,
              }).then((result) => {
                if (result.code == 0) {
                  window.alert("Success");
                  deleteBotCallback();
                } else {
                  window.alert("Fail");
                }
              });
            }}
          >
            刪除
          </Button>
        </Box>

        <div style={{ height: 18 }} />
      </Box>
    </Box>
  );
};

export const UploadContainer = ({
  walletAddress,
  bot,
  setUploadDialogOpen,
  chatRoomCallback,
}) => {
  const [knowledgeBaseList, setKnowledgeBaseList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getKnowledgeBaseList = () => {
    getKnowledgeBase({
      walletAddress: walletAddress,
      botId: bot.botId,
    }).then((result) => {
      if (result.code == 0) {
        setKnowledgeBaseList(result.data);
      }
    });
  };

  useEffect(() => {
    getKnowledgeBaseList();
  }, []);

  const updateKnowledgeBaseFromText = (content, fileName) => {
    const tokens = encode(content);
    console.log("tokens.length " + tokens.length);

    var tokenLimit = 4000;
    var embeddingTotal = Math.floor(tokens.length / tokenLimit) + 1;
    console.log("embeddingTotal " + embeddingTotal);

    var embeddingContentWords = content.length / embeddingTotal;

    var embeddingPromises = [];

    for (var index = 0; index < embeddingTotal; index++) {
      embeddingPromises.push(
        postUpdateKnowledgeBase({
          walletAddress: walletAddress,
          botId: bot.botId,
          content: content.slice(
            index * embeddingContentWords,
            (index + 1) * embeddingContentWords > content.length
              ? content.length
              : (index + 1) * embeddingContentWords
          ),
          source: fileName,
        }).then((result) => {
          console.log("result " + JSON.stringify(result));
          if (result.code == 0) {
            return true;
          } else {
            return false;
          }
        })
      );
    }

    Promise.all(embeddingPromises).then((result) => {
      var success = true;
      result.map((status) => {
        if (success && !success) {
          success = false;
        }
      });
      if (success) {
        window.alert("上傳成功");
        setLoading(false);
        getKnowledgeBaseList();
      } else {
        window.alert("Fail");
      }
    });
  };

  return (
    <Box
      style={{
        flexDirection: "column",
        display: "flex",
        background: "linear-gradient(#000000,#052F41)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "100%",
      }}
    >
      <AppBar
        sx={{
          position: "fixed",
          height: 40,
        }}
      >
        <Toolbar
          sx={{
            minHeight: "5px !important",
            display: "flex",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setUploadDialogOpen(false);
              chatRoomCallback(bot);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            文件
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        style={{
          width: 320,
          flexDirection: "column",
          display: "flex",
          alignSelf: "center",
        }}
      >
        <div style={{ height: 40 }} />

        <div style={{ height: 18 }} />

        <Box className="self-center">
          <Button component="label" sx={styleButton}>
            上傳
            <input
              type="file"
              id="file"
              name="file"
              accept=".pdf,.docx"
              hidden
              onChange={(e) => {
                if (!e.target.files) {
                  return;
                }
                const file = e.target.files[0];
                const fileName = file.name;
                const extension = file.type;

                console.log("fileName " + fileName + " type " + extension);
                setLoading(true);

                if (bot.botId) {
                  const formData = new FormData();
                  formData.append("file", file);
                  // formData.append("walletAddress", walletAddress);
                  formData.append("botId", bot.botId);
                  formData.append("fileName", fileName)

                  uploadSourceFile(formData, bot.botId, fileName)
                    .then((data) => {
                      console.log("上傳成功:", data);
                    })
                    .catch((error) => {
                      console.error("上傳失敗:", error);
                    });
                }

                var fileReader = new FileReader();

                if (extension == "application/pdf") {
                  fileReader.onload = (e) => {
                    var typedarray = new Uint8Array(e.target.result);

                    pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.10.111/pdf.worker.js`;

                    const loadingTask = pdfjsLib.getDocument(typedarray);
                    loadingTask.promise.then(function (pdf) {
                      console.log(pdf);
                      var pagesPromises = [];

                      console.log(pdf._pdfInfo.numPages);

                      for (
                        var index = 1;
                        index <= Number(pdf._pdfInfo.numPages);
                        index++
                      ) {
                        pagesPromises.push(
                          pdf
                            .getPage(index)
                            .then((page) => {
                              return page.getTextContent();
                            })
                            .then((textContent) => {
                              var result = textContent.items
                                .map(({ str }) => str)
                                .join("");
                              // console.log(result)
                              return result;
                            })
                        );
                      }

                      Promise.all(pagesPromises).then((pageList) => {
                        var pdfContent = "";
                        pageList.map((pagesText) => {
                          pdfContent += pagesText;
                        });
                        console.log(pdfContent);

                        updateKnowledgeBaseFromText(pdfContent, fileName);
                      });
                    });
                  };
                  fileReader.readAsArrayBuffer(file);
                } else {
                  fileReader.onload = (e) => {
                    const content = e.target.result;
                    const zip = new PizZip(content);
                    var zipToText = zip.files["word/document.xml"].asText();
                    // const xml = str2xml();
                    var xml = new DOMParser().parseFromString(
                      zipToText,
                      "text/xml"
                    );
                    const paragraphsXml = xml.getElementsByTagName("w:p");
                    var docxContent = "";

                    for (let i = 0, len = paragraphsXml.length; i < len; i++) {
                      let fullText = "";
                      const textsXml =
                        paragraphsXml[i].getElementsByTagName("w:t");
                      for (let j = 0, len2 = textsXml.length; j < len2; j++) {
                        const textXml = textsXml[j];
                        if (textXml.childNodes) {
                          fullText += textXml.childNodes[0].nodeValue;
                        }
                      }
                      if (fullText) {
                        docxContent += fullText;
                      }
                    }

                    console.log("docxContent " + docxContent);

                    updateKnowledgeBaseFromText(docxContent, fileName);
                  };
                  fileReader.readAsBinaryString(file);
                }
              }}
            />
          </Button>
        </Box>
        <Box className="self-center">
          {loading && <CircularProgress className="mt-4" color="success" />}
        </Box>

        <div style={{ height: 18 }} />

        {knowledgeBaseList.map((item) => {
          return (
            <Box
              style={{
                flexDirection: "column",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Typography
                style={{
                  color: "#ffffff",
                  fontSize: 18,
                }}
              >
                {item.source}
              </Typography>

              <div style={{ height: 6 }} />

              <Typography
                style={{
                  color: "#ffffff",
                  fontSize: 14,
                }}
              >
                {item.content}
              </Typography>

              <div style={{ height: 6 }} />

              <Box
                style={{
                  alignSelf: "center",
                }}
              >
                <Button
                  component="label"
                  sx={styleButton}
                  onClick={() => {
                    deleteKnowledgeBase({
                      walletAddress: walletAddress,
                      botId: bot.botId,
                      ids: [item.id],
                    }).then((result) => {
                      if (result.code == 0) {
                        window.alert("Success");
                        getKnowledgeBaseList();
                      } else {
                        window.alert("Fail");
                      }
                    });
                  }}
                >
                  Delete
                </Button>
              </Box>

              <div style={{ height: 18 }} />
            </Box>
          );
        })}

        {/* <Typography style={{
                color:"#ffffff",
                fontSize: 14,
            }}>
                {fileName}
            </Typography>

            <div style={{height:18}}/>

            <Typography style={{
                color:"#ffffff",
                fontSize: 14,
            }}>
                {fileContent}
            </Typography> */}
      </Box>
    </Box>
  );
};
