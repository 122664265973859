import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Typography,
  Toolbar,
  Tooltip,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import "react-chat-elements/dist/main.css";
import { MessageList, Input } from "react-chat-elements";

import {
  getMessageList,
  sendMessage,
  sendMsg,
} from "./api/getMainnetInfuraApi";

import {
  getSysMessageList,
  sendSysMsg,
} from "./api/systemApi";

import {
  useSetShowAlert,
  useSetCopiedTxt,
  useSetNoteTxt,
  useSetTableData,
  useSetBotTitle,
  useSetBotId,
  useSetBotPdf,
} from "./state/app/hooks";
import MarkdownPreview from "@uiw/react-markdown-preview";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";

export const MessageContainer = ({
  setPage,
  walletAddress,
  bot,
  setMessageDialogOpen,
  uploadCallback,
  avatarCallback,
}) => {
  const setShowAlert = useSetShowAlert();
  const setCopiedTxt = useSetCopiedTxt();
  const setNoteTxt = useSetNoteTxt();
  const setTableData = useSetTableData();
  const setBotTitle = useSetBotTitle();
  const setBotId = useSetBotId();
  const setBotPdf = useSetBotPdf();

  const ref = useRef(null);
  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [messageList, setMessageList] = useState([]);
  const [sliderValue, setSliderValue] = useState(0.7);
  const [sourcePdfs, setsourcePdfs] = useState([]);
  const [isISOdisabled, setIsISOdisabled] = useState(false);

  const noteText = useSelector((state) => state.app.noteText);

  useEffect(() => {
    getMessageList({
      walletAddress: walletAddress,
      botId: bot.botId,
    }).then((result) => {
      if (result.code === 0) {
        // https://github.com/Detaysoft/react-chat-elements MessageBox props
        const updatedData = result.data.map((item) => ({
          ...item,
          date: new Date(item.date),
          // replyButton: true,
          forwarded: true,
        }));
        setMessageList(updatedData);      
      }
    });
  }, []);

  useEffect(() => {
    getSysMessageList({
      walletAddress: walletAddress,
      botId: bot.botId,
    }).then((result) => {
console.log(33333, result);
      if (result.error === true) {
    
        if(result.body.length <3) {

          let systemMsg = `列出溫室氣體排放總量表 類別1到6 CO2的排放量的數據 格式若小數取至點第二位 若為整數無需小數位 回覆無需引言 找不到資料的類別就直接忽略 請回覆成陣列的格式 例如 [ { category: "類別一", amount: "24,605" }, { category: "類別二", amount: "100,605" }, ]; amount必定為一個數值`;

          sendSysMsg({
            tag:'c1',
            walletAddress: walletAddress,
            botId: bot.botId,
            message: systemMsg,
            threshold: sliderValue || 0.7,
            streaming: true,
          }).then((stream) => {
            const reader = stream.getReader();
    
            (async () => {
              var response = "";
              while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  break;
                }
                var text = new TextDecoder("utf-8").decode(value);
                const aiIndex = text.indexOf("%ai%");
                if (aiIndex !== -1) {
                  // 取 %ai% 前面的字串並轉成 JSON
                  const jsonPart = text.substring(0, aiIndex);
                  let jsonResponse;
                  try {
                    jsonResponse = JSON.parse(jsonPart);
                    console.log("JSON 解析成功:", jsonResponse);
                  } catch (error) {
                    console.error("JSON 解析錯誤:", error);
                  }
      
                  // 取 %ai% 後的字串並寫入 response
                  const aiText = text.substring(aiIndex + 4);
                  response += aiText;
                } else {
                  // 將整個 text 寫入 response
                  response += text;
                }
                // setMessageDialogOpen(false);
              }
            })();
          });
          return;
        };

        // https://github.com/Detaysoft/react-chat-elements MessageBox props
        const updatedData = result.body.map((item) => ({
          ...item,
          date: new Date(item.date),
          forwarded: true,
        }));

        if(updatedData && updatedData[updatedData.length-1]) {
          let filteredText = updatedData[updatedData.length-1]?.text.replace(/\n/g, '').trim();
  console.log(666666, filteredText);
          let jsonString = filteredText.replace(/(\w+):/g, '"$1":');
  
          let jsonObject;
          try {
            jsonObject = JSON.parse(jsonString);
            setTableData(jsonObject);
          } catch (error) {
            // setIsISOdisabled(true);
            console.error("字串轉換為物件時發生錯誤:", error);
          }
        }
    
      }
    });
  }, []);

  useEffect(() => {
    if (messageList.length != 0) {
      scrollToBottom();
    }
  }, [messageList]);

  useEffect(() => {
    if (bot.sourceUrl) {
      setsourcePdfs(bot.sourceUrl);
      setBotPdf(bot.sourceUrl?.[0]);
    }

    setBotTitle(bot.title);
    setBotId(bot.botId);
  }, [bot]);

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  };

  const handleForwardMessageClick = (message) => {
    setShowAlert(true);
    if (message.text) {
      navigator.clipboard
        .writeText(message.text.props.source)
        .then(() => {
          const clipboard = message.text.props.source;
          setCopiedTxt(clipboard);
          noteText === ""
            ? setNoteTxt(clipboard)
            : setNoteTxt(`${noteText}\n\n${clipboard}`);
        })
        .catch((err) => {
          console.error("Failed to copy message:", err);
        });
    }
  };

  const formattedMessages = messageList.map((msg) => ({
    ...msg,
    text: <MarkdownPreview source={msg.text} />,
  }));

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    // callApi(newValue);
  };

  const sendMessageToBots = () => {
    if (inputRef.current.value === "") {
      return;
    }

    var inputMsg = inputRef.current.value;

    inputRef.current.value = "";

    setMessageList((prev) => [
      ...prev,
      {
        position: "right",
        type: "text",
        text: inputMsg,
      },
    ]);

    sendMsg({
      walletAddress: walletAddress,
      botId: bot.botId,
      message: inputMsg,
      threshold: sliderValue || 0.7,
      streaming: true,
    }).then((stream) => {
      const reader = stream.getReader();

      setMessageList((prev) => [
        ...prev,
        {
          position: "left",
          type: "text",
          text: "",
        },
      ]);

      (async () => {
        var response = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          var text = new TextDecoder("utf-8").decode(value);
          const aiIndex = text.indexOf("%ai%");
          if (aiIndex !== -1) {
            // 取 %ai% 前面的字串並轉成 JSON
            const jsonPart = text.substring(0, aiIndex);
            let jsonResponse;
            try {
              jsonResponse = JSON.parse(jsonPart);
              console.log("JSON 解析成功:", jsonResponse);
            } catch (error) {
              console.error("JSON 解析錯誤:", error);
            }

            // 取 %ai% 後的字串並寫入 response
            const aiText = text.substring(aiIndex + 4);
            response += aiText;
          } else {
            // 將整個 text 寫入 response
            response += text;
          }

          setMessageList((prev) => [
            ...prev.slice(0, prev.length - 1),
            {
              ...prev[prev.length],
              position: "left",
              type: "text",
              text: response,
            },
          ]);
        }
      })();
    });

    // sendMessage({
    //   walletAddress: walletAddress,
    //   botId: bot.botId,
    //   message: inputMsg,
    // }).then((stream) => {
    //   const reader = stream.getReader();

    //   setMessageList((prev) => [
    //     ...prev,
    //     {
    //       position: "left",
    //       type: "text",
    //       text: "",
    //     },
    //   ]);

    //   (async () => {
    //     var response = "";
    //     while (true) {
    //       const { done, value } = await reader.read();
    //       if (done) {
    //         break;
    //       }
    //       var text = new TextDecoder("utf-8").decode(value);
    //       response += text;
    //       setMessageList((prev) => [
    //         ...prev.slice(0, prev.length - 1),
    //         {
    //           ...prev[prev.length],
    //           position: "left",
    //           type: "text",
    //           text: response,
    //         },
    //       ]);
    //     }
    //   })();
    // });
  };
  return (
    <>
      <Box>
        <AppBar
          sx={{
            position: "fixed",
            height: 40,
          }}
        >
          <Toolbar
            sx={{
              minHeight: "5px !important",
              display: "flex",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setMessageDialogOpen(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {bot.title}
              <Tooltip title="複製分享網址">
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="menu"
                  onClick={(event) => {
                    const currentLocation = window.location;
                    const curUrl = `${currentLocation.protocol}//${
                      currentLocation.hostname
                    }${currentLocation.port ? `:${currentLocation.port}` : ""}`;
                    navigator.clipboard.writeText(curUrl + "?id=" + bot.botId);
                  }}
                >
                  <MobileScreenShareIcon />
                </IconButton>
              </Tooltip>
            </Typography>

            <IconButton
              size="large"
              color="inherit"
              aria-label="menu"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              {
                // bot.walletAddress==walletAddress?
                <MenuItem
                  onClick={() => {
                    uploadCallback(bot);
                  }}
                >
                  文檔
                </MenuItem>
                // :
                // <div/>
              }
              {
                // bot.walletAddress==walletAddress?
                <MenuItem
                  onClick={() => {
                    avatarCallback(bot);
                  }}
                >
                  管理
                </MenuItem>
                // :
                // <div/>
              }
              {
                <MenuItem
                  onClick={() => {
                    setMessageDialogOpen(false);
                    setPage(4);
                  }}
                  disabled={isISOdisabled}
                >
                  ISO 14064
                </MenuItem>
              }
              <MenuItem>參數 {sliderValue}</MenuItem>
              <Slider
                defaultValue={sliderValue}
                step={0.1}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={0.1}
                max={1}
                onChange={handleSliderChange}
              />
              {sourcePdfs.length >0 &&
                sourcePdfs.map((pdf) => {
                  return (
                    <>
                    <MenuItem
                      key={pdf.url} 
                      onClick={() => {
                        window.open(pdf.url);
                      }}
                    >
                      {pdf.name}
                    </MenuItem>
                    </>
                  );
                })}
            </Menu>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            height: 40,
          }}
        />
        <MessageList
          className="message-list"
          lockable={false}
          toBottomHeight={"100%"}
          dataSource={formattedMessages}
          // onReplyMessageClick={handleReplyMessageClick}
          onForwardClick={handleForwardMessageClick}
        />
        <Box
          sx={{
            height: 48,
          }}
        />
        <div ref={ref} />
        <Box
          style={{
            position: "fixed",
            width: "100%",
            top: "auto",
            bottom: 0,
            backgroundColor: "#ffffff",
          }}
        >
          <Input
            referance={inputRef}
            placeholder="Type here..."
            multiline={true}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                sendMessageToBots();
              }
            }}
            rightButtons={
              <Button onClick={sendMessageToBots}>
                <SendIcon />
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  );
};
