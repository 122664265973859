import React, { useEffect, useState } from "react";

import "react-chat-elements/dist/main.css";
import { ChatList } from "react-chat-elements";

// import { getExploreList } from "../api/getMainnetInfuraApi";
import { getMyChatList } from "../api/getMainnetInfuraApi";

export const ChatPdf = ({ walletAddress, chatRoomCallback }) => {
  // const [exploreList, setExploreList] = useState([]);
  const [chatList, setChatList] = useState([]);
  // 2: b8515945-5e27-4e38-a01e-2f952210b158
  // 20: 877a7d26-76b4-4a41-883e-1decc084a305
  const chatChannel = [
    {
      botId: "b8515945-5e27-4e38-a01e-2f952210b158",
      walletAddress: "0xf361e14DFe6242D20FC2B23A5b36eb2CCD753349",
      avatar: "/pdf.png",
      title: "AI文檔",
      subtitle: "全部文檔",
      description: "",
    },
  ];

  // useEffect(() => {
  //   console.log(222222, walletAddress);
  //   getExploreList().then((result) => {
  //     if (result.code == 0) {
  //       const bots = result.data.map((channel) => channel.botId);
  //       chatChannel[0].bots = result.data; //bots;
  //       setExploreList(chatChannel);
  //       chatRoomCallback(chatChannel[0]); // auto open the first channel
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }

    getMyChatList({
      walletAddress: walletAddress,
    }).then((result) => {
      if (result.code == 0) {
        chatChannel[0].bots = result.data; //bots;
        setChatList(chatChannel);
        chatRoomCallback(chatChannel[0]); // auto open the first channel
      }
    });
  }, [walletAddress]);

  return (
    <ChatList
      className="chat-list"
      dataSource={chatList}
      onClick={(bot) => {
        chatRoomCallback(bot);
      }}
    />
  );
};
