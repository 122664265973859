import React, { useEffect, useState, useRef } from "react";
import {
  AppBar,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import "./Message.css";
import MarkdownPreview from "@uiw/react-markdown-preview";
import "react-chat-elements/dist/main.css";
import { MessageList, Input } from "react-chat-elements";

import { sendMessage, sendMsg } from "../api/getMainnetInfuraApi";

export const Message = ({
  walletAddress,
  bot,
  setChatDialogOpen,
  setPage,
  uploadCallback,
  avatarCallback,
  keyCallback,
}) => {
  const ref = useRef(null);
  const inputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [messageList, setMessageList] = useState([]);
  const [sourceTip, setSourceTip] = useState("tip");
  const storedItem = localStorage.getItem("keywords");
  const storedKeywords = storedItem
    ? JSON.parse(storedItem)
    : ["碳權", "公正轉型的定義"];
  const [keyWords, setKeyWords] = useState(storedKeywords || []);

  const [chatId, setChatId] = useState(0);

  useEffect(() => {
    const storedKeywords = localStorage.getItem("keywords");
    if (storedKeywords) {
      setKeyWords(JSON.parse(storedKeywords));
    }
  }, []);

  useEffect(() => {
    if (messageList.length != 0) {
      scrollToBottom();
    }
  }, [messageList]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const citations = document.querySelectorAll(".citation");
  //     citations.forEach((citation, index) => {
  //       console.log(`Processing citation ${index + 1}`);

  //       const tooltip = document.createElement("span");
  //       tooltip.className = "tooltip";

  //       const titleElement = document.createElement("div");
  //       titleElement.className = "tooltip-title";

  //       const contentElement = document.createElement("div");
  //       contentElement.className = "tooltip-content";

  //       tooltip.appendChild(titleElement);
  //       tooltip.appendChild(contentElement);

  //       citation.appendChild(tooltip);

  //       const title =
  //         citation.getAttribute("data-title") || "No title provided";
  //       const content =
  //         citation.getAttribute("data-content") || "No content provided";

  //       console.log(`Title: ${title}`);
  //       console.log(`Content: ${content}`);

  //       try {
  //         titleElement.textContent = title;
  //         contentElement.textContent = content;
  //         console.log("Tooltip content set successfully");
  //       } catch (error) {
  //         console.error("Error setting text content:", error);
  //       }
  //     });
  //   }, 2200);
  // }, []);

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  };

  async function sendMessageToBot(params, chatId) {
    if (chatId >= bot.bots.length) {
      return;
    }



    const response = await sendMessage(params);
    const reader = response.getReader();

    // setMessageList((prev) => [
    //   ...prev,
    //   {
    //     position: "left",
    //     type: "text",
    //     text: "",
    //   },
    // ]);

    var responseText = "";
    while (true) {
      const { done, value } = await reader.read();
      // <MarkdownPreview source={msg.text + from} />,
      if (done) {
        


        const from = chatId
        ? `[${bot.bots[chatId]?.subtitle}](${window.location.origin}?id=${
            chatId
          })` 
        : "";
// class="citation"
        const pageFrom =
          `<br><br><em>` +
          `<Tooltip title="${sourceTip}">`+
          `資料來源: <a href="#" target="_blank" >`+ from
          +`</a>` + `</Tooltip>` +
          "</em>";

        setMessageList((prev) => [
          ...prev.slice(0, prev.length - 1),
          {
            ...prev[prev.length - 1], // 獲取最後一個元素，注意這裡應該使用 prev.length - 1
            position: "left",
            type: "text",
            text: <MarkdownPreview source={prev[prev.length - 1].text + pageFrom} />,
            chatId: chatId,
          },
        ]);
        break;
      }
      if (value[0] == 123) {
        // console.log("無法回答", params, chatId, bot.bots);
        const nextChatId = chatId + 1;
        const nextBotId = bot.bots[nextChatId]?.botId;
        setChatId(nextChatId);
        await sendMessageToBot(
          {
            walletAddress: walletAddress,
            botId: nextBotId,
            message: params.message,
          },
          nextChatId
        );

        if (nextChatId === bot.bots.length) {
          setMessageList((prev) => [
            ...prev,
            {
              position: "left",
              type: "text",
              text: "找不到相關資訊",
            },
          ]);
          setChatId(0);
        }
        break;
      }
      var text = new TextDecoder("utf-8").decode(value);

      responseText += text;
      setMessageList((prev) => [
        ...prev.slice(0, prev.length - 1),
        {
          ...prev[prev.length],
          position: "left",
          type: "text",
          text: responseText,
          chatId: chatId,
        },
      ]);
    }

    const res = await sendMsg(params);
    if (res.code === 200) {
      setSourceTip(res.body.response);
      console.log("sourceTip:", res.body.response);
    }

  }

  const sendKeyWordToBot = (keyWord) => {
    const botId = bot.bots[chatId]?.botId;

    setMessageList((prev) => [
      ...prev,
      {
        position: "right",
        type: "text",
        text: keyWord,
      },
    ]);

    sendMessageToBot(
      {
        walletAddress: walletAddress,
        botId: botId,
        message: keyWord,
      },
      chatId
    );
  };
  const sendMessageToBots = () => {
    if (inputRef.current.value == "") {
      return;
    }
    const botId = bot.bots[chatId]?.botId;
    var inputMsg = inputRef.current.value;

    // inputRef.current.value = "";

    setMessageList((prev) => [
      ...prev,
      {
        position: "right",
        type: "text",
        text: inputMsg,
        chatId: chatId,
      },
    ]);

    sendMessageToBot(
      {
        walletAddress: walletAddress,
        botId: botId,
        message: inputMsg,
      },
      chatId
    );
  };

  const formattedMessages = messageList.map((msg) => {
    const from = msg.chatId
      ? '<br><br><em className="citation text-sm">資料來源' +
        `[${bot.bots[msg.chatId]?.subtitle}](${window.location.origin}?id=${
          msg.chatId
        })` +
        "</em>"
      : "";
    return {
      ...msg,
      text: msg.text + from, // <MarkdownPreview source={msg.text + from} />,
    };
  });

  return (
    <Box style={{}}>
      <AppBar className="fixed h-10">
        <Toolbar
          sx={{
            minHeight: "5px !important",
            display: "flex",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setChatDialogOpen(false);
              setPage(2);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {bot.title}
          </Typography>

          <IconButton
            size="large"
            color="inherit"
            aria-label="menu"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            <MenuItem
              onClick={() => {
                keyCallback(bot);
              }}
            >
              搜尋詞
            </MenuItem>
            {bot.walletAddress == walletAddress ? (
              <MenuItem
                onClick={() => {
                  uploadCallback(bot);
                }}
              >
                文檔
              </MenuItem>
            ) : (
              <div />
            )}
            {bot.walletAddress == walletAddress ? (
              <MenuItem
                onClick={() => {
                  avatarCallback(bot);
                }}
              >
                管理
              </MenuItem>
            ) : (
              <div />
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 40,
        }}
      />
      <MessageList
        className="message-list"
        lockable={false}
        toBottomHeight={"100%"}
        dataSource={messageList}
      />
      <Box
        sx={{
          height: 48,
        }}
      />
      <div ref={ref} />
      <Box
        style={{
          position: "fixed",
          width: "100%",
          top: "auto",
          bottom: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <Input
          referance={inputRef}
          placeholder="關鍵詞詢問..."
          multiline={false}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              sendMessageToBots();
            }
          }}
          leftButtons={
            <>
              <Tooltip title={bot.bots[chatId]?.subtitle} placement="top">
                <Button
                  onClick={() => {
                    if (chatId + 1 >= bot.bots.length) {
                      setChatId(0);
                      return;
                    }
                    if (chatId < bot.bots.length) {
                      setChatId(chatId + 1);
                    }
                  }}
                >
                  {`${
                    chatId + 1 >= bot.bots.length ? bot.bots.length : chatId + 1
                  }/${bot.bots.length}`}
                  <AssignmentReturnedIcon />
                </Button>
              </Tooltip>
            </>
          }
          rightButtons={
            <>
              {keyWords.map((keyWord, index) => (
                <Chip
                  key={index}
                  label={keyWord}
                  variant="outlined"
                  onClick={() => sendKeyWordToBot(keyWord)}
                />
              ))}
              <Button onClick={sendMessageToBots}>
                <SendIcon />
              </Button>
            </>
          }
        />
      </Box>
    </Box>
  );
};
